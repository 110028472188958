import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import Pastille from '../../textual/Pastille';
import { CiLocationOn, CiMail, } from 'react-icons/ci';
import Stack from '../../../box/container/Stack';
import Title from '../../textual/Title';

const ViewProfilePopup = ({ open, onClose, profile, profile_picture }) => {
    const [error, setError] = useState(null);

    if (!profile) {
        setError("Aucun utilisateur")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Utilisateur: ${profile?.id}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : profile ? (

                <Stack direction={"column"}>
                    <Title level={3}>Profil de {profile.name} {profile.surname}</Title>
                    <img
                        src={profile_picture || "/illustration/svg/utilisateur.svg"}
                        alt={profile?.name}
                        style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit: 'cover' }}
                    />
                    <Stack direction={"column"} spacing={"5px"}>
                        <Text>
                            Date de naissance : {profile.birthdate ? new Date(profile.birthdate).toLocaleDateString() : 'Non renseignée'}
                        </Text>
                        <Text>
                            {profile.name && profile.surname
                                ? `${profile.name} ${profile.surname}`
                                : 'Nom et prénom non renseignés'}
                        </Text>
                        {profile.email ? (
                            <Pastille variant={"success"}>
                                <CiMail /> {profile.email}
                            </Pastille>
                        ) : (
                            <Pastille variant={"warning"}>
                                Email non renseigné
                            </Pastille>
                        )}
                        <Text>
                            {profile.phone_number ? profile.phone_number : 'Numéro de téléphone non renseigné'}
                        </Text>
                        <Text>
                            {profile.website ? profile.website : 'Site web non renseigné'}
                        </Text>
                        <Pastille variant={"success"}>
                            {profile.city ? <><CiLocationOn />{profile.city}</> : 'Ville non renseignée'}
                        </Pastille>
                        <Text>
                            Description : {profile.bio ? profile.bio : 'Description non renseignée'}
                        </Text>
                    </Stack>
                </Stack>

            ) : (
                <Text>Aucun utilisateur trouvé.</Text>
            )}
        </Popup>
    );
};

export default ViewProfilePopup;
