import Chance from 'chance';
import { CiCircleRemove, CiWarning, CiClock2, CiCircleCheck } from "react-icons/ci";

export const calculateTaskDate = async (date, days) => {
  const splitDate = await dateToAWSFormat(date);
  let newDate = new Date(splitDate);
  newDate.setDate(newDate.getDate() - days);

  let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
  return newDate.toLocaleDateString('fr-FR', options);
}

export const dateToAWSFormat = async (date) => {
  if (!date) {
    console.error('Invalid date value:', date);
    return;
  }

  if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return date;
  }

  let dateArray = date.split("/");
  return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
}


export const dateToFRFormat = (date) => {
  if (!date || !date.date) {
    console.error('Invalid date value:', date);
    return;
  }

  // Check if the date is already in the "dd/mm/yyyy" format
  if (date.date.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return date.date; // Return the date as it is
  }

  let dateArray = date.date.split("-");
  return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
}


export const defColorDate = (date) => {
  if (date.doneStatus === "true") {
    return { color: 'success', icon: <CiCircleCheck /> };
  }
  const currentDate = new Date();
  const compareDate = new Date(date.date);

  const diffTime = compareDate - currentDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 0 || currentDate >= compareDate) {
    return { color: 'danger', icon: <CiCircleRemove /> };
  } else if (diffDays <= 0) {
    return { color: 'danger', icon: <CiCircleRemove /> };
  } else if (diffDays < 60) {
    return { color: 'warning', icon: <CiWarning /> };
  } else {
    return { color: 'primary', icon: <CiClock2 /> };
  }
}

export async function randomClients() {
  const chance = new Chance();
  let tempDate = chance.date({ year: 2024 });
  console.log("tempdate", tempDate);
  let clientTests = {
    name: chance.first(),
    surname: chance.last(),
    email: chance.email(),
  };
  console.log("clientTests", clientTests.date);
  return clientTests;
}
