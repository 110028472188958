import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextInput from './Textinput';
import Textarea from './Textarea';
import DateInput from './DateInput';
import Button from '../button/Button';
import * as Tool from '../../../tools/Calculate';
import { useData } from '../../../contexts/DataContext';
import { useParams } from 'react-router-dom';
import { notifySuccess, notifyError } from '../Toastify';
import Stack from '../../box/container/Stack';
import IconButton from '../button/IconButton';
import SelectSearchable from '../form/SelectSearchable';
import { useNavigate } from 'react-router-dom';
import Tips from '../textual/Tips';
import { CiTrash } from 'react-icons/ci';
import { confirm } from "../../confirm/ConfirmGlobal";
import Text from '../textual/Text';
import AnimationComponent from '../../animation/Animation';
import Empty from '../../animation/storageAnimation/empty.json';
import { IoMdAdd } from 'react-icons/io';
import Accordion from '../Accordion';
import { ROUTES } from '../../../routes/routes';

const StepIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
`;

const Dot = styled.div`
  width: ${(props) => (props.$active ? '16px' : '12px')};
  height: 6px;
  border-radius: 30px;
  background-color: ${(props) => (props.$active ? 'var(--main-color)' : 'var(--grey-color)')};
`;

const StepNavigation = ({ currentStep, handlePreCalc, handleCreateNewAudit, setCurrentStep, selectedTemplateId }) => {
  console.log(selectedTemplateId)
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderActions = () => {
    switch (currentStep) {
      case 1:
        return (
          <Button variant="primary" onClick={() => setCurrentStep(2)} disable={selectedTemplateId === null} loading={false}>
            Suivant
          </Button>
        );
      case 2:
        return (
          <Button variant="primary" onClick={() => setCurrentStep(3)}>
            Suivant
          </Button>
        );
      case 3:
        return (
          <Button variant="primary" onClick={handlePreCalc}>
            Faire la simulation
          </Button>
        );
      case 4:
        return (
          <Button variant="primary" onClick={handleCreateNewAudit}>
            Créer le nouvel Audit
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Stack direction="column">
      <Stack direction="row" justify="end" align="center" width={"100%"}>
        {currentStep > 1 && (
          <IconButton variant="basique" onClick={handleBack}>
            Retour
          </IconButton>
        )}
        {renderActions()}
      </Stack>
    </Stack>
  );
};

const FormCreateAudit = ({ auditTemplates, client }) => {
  const navigate = useNavigate();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [inputValues, setInputValues] = useState([]);
  const [date, setDate] = useState('');
  const [nameAudit, setNameAudit] = useState('');
  const [typeAudit, setTypeAudit] = useState('');
  const [listTasks, setListTasks] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const { createAnAudit, createATask } = useData();
  const { id } = useParams();

  useEffect(() => {
    if (selectedTemplateId && auditTemplates) {
      const auditTemplate = auditTemplates.find(template => template.id === selectedTemplateId);
      setNameAudit(auditTemplate.name);
      setTypeAudit(auditTemplate.type);
      setListTasks(auditTemplate.tasksTemplates.items);
      setInputValues(Array(auditTemplate.tasksTemplates.items.length).fill({ id: '', date: '', name: '' }));
    }
  }, [selectedTemplateId, auditTemplates]);

  const handlePreCalc = async () => {
    if (date.length !== 10) {
      notifyError('Veuillez entrer une date valide');
      return;
    }

    let array = [];
    for (let i = inputValues.length - 1; i >= 0; i--) {
      const myDate = await Tool.calculateTaskDate(date, listTasks[i].daysBeforeTargetDay);
      let temp = {
        id: listTasks[i].id,
        date: myDate,
        dateAws: myDate && (await Tool.dateToAWSFormat(myDate)),
        name: listTasks[i].name,
        description: listTasks[i].description,
      };
      array.push(temp);
    }
    setInputValues(array);
    setCurrentStep(4);
  };

  const handleCreateNewAudit = async () => {
    if (!inputValues || !inputValues[0].date) {
      notifyError('Veuillez calculer la simulation avant de créer un audit');
      return;
    }
    if (!nameAudit || !typeAudit) {
      notifyError('Veuillez remplir les champs obligatoires');
      return;
    }
    if (date.length !== 10) {
      notifyError('Veuillez entrer une date valide');
      return;
    }
    const formatDate = await Tool.dateToAWSFormat(date);
    const newAudit = await createAnAudit(id, nameAudit, 'En cours', formatDate, typeAudit);
    const newAuditId = newAudit.data.createAudit.id;
    for (let i = 0; i < inputValues.length; i++) {
      const formatDate = await Tool.dateToAWSFormat(inputValues[i].date);
      await createATask(newAuditId, inputValues[i].id, inputValues[i].name, formatDate, inputValues[i].description);
    }
    notifySuccess('Audit créé avec succès');
    navigate(ROUTES.ADMIN_CLIENT_DETAILS(client.id));
  };

  const handleRemoveTask = async (index) => {
    try {
      const confirmed = await confirm({
        title: "Voulez-vous vraiment supprimer cette tâche ?",
        content: "Cette tâche sera supprimée de cet audit.",
        variant: "danger"
      });

      if (confirmed) {
        const updatedInputValues = [...inputValues];
        updatedInputValues.splice(index, 1);
        setInputValues(updatedInputValues);
        notifySuccess('Tâche supprimée');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la tâche :', error);
      notifyError('Erreur lors de la suppression de la tâche');
    }
  };

  const handleAddTask = () => {
    setInputValues([...inputValues, { id: '', name: '', date: '', description: '' }]);
  };

  const handleTaskNameChange = (index, value) => {
    const newTasks = [...inputValues];
    newTasks[index].name = value;
    setInputValues(newTasks);
  };

  const handleTaskDateChange = (index, value) => {
    const newTasks = [...inputValues];
    newTasks[index].date = value;
    setInputValues(newTasks);
  };

  const handleTaskDescriptionChange = (index, value) => {
    const newTasks = [...inputValues];
    newTasks[index].description = value;
    setInputValues(newTasks);
  };

  if (!auditTemplates || auditTemplates.length === 0) {
    return (
      <Stack direction="column" align="center">
        <AnimationComponent animationData={Empty} width="150px" />
        <Text>
          Vous n'avez aucun template d'audit
        </Text>
        <IconButton variant="action" to={ROUTES.ADMIN_AUDIT_TEMPLATES}>
          <IoMdAdd /> Créer un template
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <StepIndicator>
        {[...Array(4)].map((_, index) => (
          <Dot key={index} $active={currentStep === index + 1} />
        ))}
      </StepIndicator>
      {currentStep === 1 && (
        <Stack direction="column" animationType={"fadeIn"}>
          <Tips variant={"success"}>1- Choisissez un template d'audit pour l'appliquer à ce client.</Tips>
          <SelectSearchable
            label="Rechercher un template"
            options={auditTemplates.map(audit => ({
              id: audit.id,
              label: audit.name,
            }))}
            value={selectedTemplateId}
            onSelect={option => {
              if (option) {
                setSelectedTemplateId(option.id);
              } else {
                setSelectedTemplateId(null);
              }
            }}
          />

        </Stack>
      )}

      {currentStep === 2 && selectedTemplateId && (
        <Stack direction="column" animationType={"fadeIn"}>
          <Tips variant={"success"}>2- Ici vous pouvez changer le nom et le type de l'audit spécialement pour ce client.</Tips>
          <TextInput
            type="text"
            label={"Nom de l'audit*"}
            value={nameAudit}
            onChange={(e) => setNameAudit(e.target.value)}
            variant="blue"
          />
          <TextInput
            type="text"
            label={"Type d'audit*"}
            value={typeAudit}
            onChange={(e) => setTypeAudit(e.target.value)}
            variant="blue"
          />
        </Stack>
      )}

      {currentStep === 3 && (
        <Stack direction="column" animationType={"fadeIn"}>
          <Tips variant={"success"}>3- Dans cette étape vous devez rentrer une date cible et calculer les dates de chaque tâche par rapport à cette date.</Tips>
          <DateInput
            type="text"
            label={"Date cible*"}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            variant="blue"
          />
        </Stack>
      )}

      {currentStep === 4 && (
        <Stack direction="column" animationType={"fadeIn"}>
          <Tips variant={"success"}>4- Vous pouvez maintenant visualiser les tâches calculées et les ajuster si nécessaire avant de créer l'audit.</Tips>
          {inputValues.map((task, index) => (
            <Stack direction="row" key={index} align="center" justify="space-between">
              <Accordion key={index} title={`Tâche ${index + 1} : ${task.name}`} defaultOpen={true}>
                <Stack direction="column">
                  <TextInput
                    type="text"
                    label={`Nom de la tâche ${index + 1}`}
                    value={task.name}
                    onChange={(e) => handleTaskNameChange(index, e.target.value)}
                    variant="blue"
                  />
                  <DateInput
                    type="text"
                    label={`Date de la tâche ${index + 1}`}
                    value={task.date}
                    onChange={(e) => handleTaskDateChange(index, e.target.value)}
                    variant="blue"
                  />
                  <Textarea
                    maxCharCount={250}
                    label={`Description de la tâche ${index + 1}`}
                    value={task.description}
                    onChange={(e) => handleTaskDescriptionChange(index, e.target.value)}
                    variant="blue"
                  />
                </Stack>
              </Accordion>
              <IconButton variant="danger" onClick={() => handleRemoveTask(index)}>
                <CiTrash />
              </IconButton>
            </Stack>
          ))}
          <IconButton variant="action" onClick={handleAddTask}>
            <IoMdAdd /> Ajouter une tâche
          </IconButton>
        </Stack>
      )}

      <StepNavigation
        currentStep={currentStep}
        handlePreCalc={handlePreCalc}
        handleCreateNewAudit={handleCreateNewAudit}
        setCurrentStep={setCurrentStep}
        selectedTemplateId={selectedTemplateId}
      />
    </Stack>
  );
};

export default FormCreateAudit;

