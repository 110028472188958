import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { useUser } from '../../../../../contexts/UserContext';
import { get } from 'aws-amplify/api';
import NotFound from '../../../../../states/NotFound';
import Loading from '../../../../../states/Loading';
import Section from '../../../../../components/box/section/Section';
import Stack from '../../../../../components/box/container/Stack';
import CustomerAuditCard from '../../../../../components/CustomerAuditCard';
import fetchS3File from '../../../../../hooks/getdata/fetchs3File';
import Hero from '../../../../../components/box/section/Hero';

export default function CustomerAuditDetails() {
    const { user } = useUser();
    const { id, auditId } = useParams();
    const [error, setError] = useState(null);
    const [clientDetail, setClientDetail] = useState(null);
    const [logo, setLogo] = useState(null);


    const fetchClient = useCallback(async (token) => {

        try {
            const restOperation = await get({
                apiName: 'clientsApi',
                path: `/clients/audits/${id}/${auditId}`,
                options: {
                    headers: {
                        Token: `user_token ${token}`,
                    },
                },
            });

            const response = await restOperation.response;
            const clientData = await response.body.json();
            return clientData;
        } catch {
            console.error("error during fetch");
            return null;
        }
    }, [id, auditId]);

    useEffect(() => {
        const loadClientDetails = async () => {
            if (user?.accessToken) {
                const clientData = await fetchClient(user.accessToken);
                if (!clientData) {
                    setError(404);
                } else {
                    setClientDetail(clientData);
                    if (clientData && clientData.client.logo) {
                        const logoUrl = await fetchS3File(clientData.client.logo, clientData.client.logoIdentityId);
                        setLogo(logoUrl);
                    } else {
                        setLogo(null);
                    }
                }
            }
        };

        loadClientDetails();
    }, [fetchClient, user?.accessToken, id]);

    if (error === 404) {
        return <NotFound />;
    }

    if (error) {
        return <p>An unknown error occurred. Please try again later.</p>;
    }

    if (!clientDetail) return <Loading label={`chargement de l'audit...`} />;


    return (
        <Hero spacing="30px">
            <Stack direction={"column"} spacing={"10px"}>
                <Stack direction={"column"} spacing={"30px"}>
                    {
                        clientDetail.client && (
                            <CustomerAuditCard
                                audit={clientDetail.audits}
                                logo={logo ? logo : null}
                                clientId={clientDetail.client.id}
                                clientName={clientDetail.client.name}
                            />
                        )
                    }
                </Stack>
            </Stack>
        </Hero>
    );

}
