import React, { useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import IconButton from "./ui/button/IconButton";
import { CiCircleCheck, CiCircleRemove, CiTrash, CiEdit } from "react-icons/ci";
import { SiMicrosoftexcel } from "react-icons/si";
import { PiFilePdfDuotone, PiPaperclipLight } from "react-icons/pi";
import Title from "./ui/textual/Title";
import Text from "./ui/textual/Text";
import { useData } from "../contexts/DataContext";
import { confirm } from "../components/confirm/ConfirmGlobal";
import { generateClient, patch } from "aws-amplify/api";
import {
    notifySuccess,
    notifyError,
} from '../components/ui/Toastify';
import * as Tool from "../tools/Calculate";
import Pastille from "./ui/textual/Pastille";
import Checkbox from "./ui/checkbox/CheckboxItem";
import Table from './ui/table/Table';
import Th from './ui/table/Th';
import Td from './ui/table/Td';
import { useUser } from '../contexts/UserContext';
import Stack from './box/container/Stack';
import { IoMdAdd } from 'react-icons/io';
import AnimationComponent from './animation/Animation';
import Empty from './animation/storageAnimation/empty.json';
import {
    deleteTasks
} from "../graphql/mutations";
import { dateConverter } from '../tools/dateConverter';
import Tips from './ui/textual/Tips';
import { usePopup } from '../contexts/PopupContext';
import AddTaskPopup from './ui/popup/allPopups/AddTaskPopup';
import Span from './ui/textual/Span';
import SmallCircleProgress from './ui/progress-bar/SmallCircleProgress';
import ViewTaskPopup from './ui/popup/allPopups/ViewTaskPopup';
import EditTaskPopup from './ui/popup/allPopups/EditTaskPopup';
import Link from "./ui/textual/Link";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import Select from './ui/form/Select';
import EditAuditPopup from './ui/popup/allPopups/EditAuditPopup';
import { capitalizeFirstLetter } from '../tools/capitalizeFirstLetter';
import AddEntityDocumentRelationPopup from './ui/popup/allPopups/AddEntityDocumentRelationPopup';
import ChipButton from './ui/button/ChipButton';
import ViewDocumentPopup from './ui/popup/allPopups/ViewDocumentPopup';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../routes/routes';
import ColoredSelect from './ui/form/ColoredSelect';
import EditTaskPopupCustomer from './ui/popup/allPopups/EditTaskPopupCustomer';
import ViewTaskPopupCustomer from './ui/popup/allPopups/ViewTaskPopupCustomer';

const client = generateClient();

const DivTrash = styled.div`
  display: flex;
  margin: -20px 20px;
  justify-content: flex-end;
  width: 100%;
`;

export default function CustomerAuditCard({ audit, logo, clientId, clientName }) {
    const { popups, openPopup, closePopup } = usePopup();
    const [tasks, setTasks] = useState(null);
    const [checkedItems, setCheckedItems] = useState({});
    const [allChecked, setAllChecked] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [deleteButtonState, setDeleteButtonState] = useState("notactive");

    const {
        deleteAnAudit,
        refresh,
        setRefresh,
    } = useData();

    const { isAdmin, user, isReltim } = useUser();

    useEffect(() => {
        if (audit) setTasks(audit.tasks.items ? audit.tasks.items : audit.tasks);
    }, [audit]);

    useEffect(() => {
        setDeleteButtonState(selectedCount > 0 ? "active" : "notactive");
    }, [selectedCount]);

    useEffect(() => {
        const count = Object.values(checkedItems).filter(value => value).length;
        setSelectedCount(count);
        setAllChecked(count === tasks?.length);
    }, [checkedItems, tasks]);

    const handleChildCheckboxChange = (taskId) => {
        const updatedCheckedItems = { ...checkedItems, [taskId]: !checkedItems[taskId] };
        setCheckedItems(updatedCheckedItems);
    };

    const handleParentCheckboxChange = () => {
        const updatedCheckedItems = {};
        if (!allChecked) {
            tasks.forEach((task) => {
                updatedCheckedItems[task.id] = true;
            });
        }
        setCheckedItems(updatedCheckedItems);
        setAllChecked(!allChecked);
    };

    const deleteCheckedTasks = async () => {
        const checkedTaskIds = Object.keys(checkedItems).filter(taskId => checkedItems[taskId]);
        try {
            if (await confirm({
                title: "Voulez-vous vraiment supprimer ces tâches ?",
                content: "Ces tâches seront supprimées définitivement et personne ne pourra y accéder",
                variant: "danger"
            })) {
                await Promise.all(checkedTaskIds.map(async taskId => {
                    await deleteTask(taskId);
                }));
                setRefresh(!refresh);
                notifySuccess(`${checkedTaskIds.length} tâches supprimées avec succès`);
                setCheckedItems({});
            }
        } catch (error) {
            console.error("Erreur lors de la suppression des tâches :", error);
            notifyError("Erreur lors de la suppression des tâches");
        }
    };

    const deleteTask = async (taskId) => {
        try {
            await client.graphql({
                query: deleteTasks,
                variables: {
                    input: {
                        id: taskId
                    }
                }
            });
        } catch (error) {
            console.error("Erreur lors de la suppression de la tâche :", error);
            throw error;
        }
    };

    async function handleDeleteAudit(id) {
        if (await confirm({
            title: "Voulez-vous vraiment supprimer cet audit ?",
            content: "Cet audit et ses tâches seront supprimés définitivement.",
            variant: "danger"
        })) {
            try {
                await deleteAnAudit(id);
                setRefresh((prev) => !prev);
            } catch (error) {
                console.error("error on deleting audit", error);
                notifyError("Erreur lors de la suppression de l'audit");
            }
        }
    }

    const totalTasks = tasks ? tasks.length : 0;
    const doneTasks = tasks ? tasks.filter(task => task.doneStatus === "true").length : 0;
    const donePercentage = totalTasks ? (doneTasks / totalTasks) * 100 : 0;

    const exportToExcel = () => {
        const data = tasks.map(task => ({
            Tâche: task.name,
            Description: task.description,
            Date: task.date,
            Statut: task.doneStatus === "true" ? 'Terminée' : 'Non terminée'
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Tâches");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, `${clientName}_${audit.name}.xlsx`);
    };


    const exportToPDF = async () => {
        const doc = new jsPDF();

        doc.setFontSize(22);
        doc.text(`${capitalizeFirstLetter(clientName)}`, 10, 10);

        doc.setFontSize(12);
        doc.text(`Date d'exportation: ${dateConverter(new Date(), 'short')}`, 10, 20);

        doc.line(10, 25, 200, 25);

        doc.setFontSize(16);
        const auditTitle = `${audit.name} (échéance le ${dateConverter(audit.targetDate, 'short')})`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const splitTitle = doc.splitTextToSize(auditTitle, pageWidth - 20);

        let currentY = 35;

        splitTitle.forEach((line) => {
            doc.text(line, 10, currentY);
            currentY += 6;
        });

        currentY += 0;

        const tableColumn = ["Tâche", "Date", "Statut"];
        const tableRows = [];

        if (Array.isArray(audit.tasks?.items) ? audit.tasks.items : audit.tasks) {
            const sortedTasks = audit.tasks?.items ? audit.tasks.items.sort((a, b) => new Date(a.date) - new Date(b.date)) : audit.tasks.sort((a, b) => new Date(a.date) - new Date(b.date));

            sortedTasks.forEach(task => {
                const taskData = [
                    task.name,
                    dateConverter(task.date, 'short'),
                    task.doneStatus === "true" ? "Terminée" : "Non terminée"
                ];
                tableRows.push(taskData);
            });
        }

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: currentY,
            margin: { left: 10, right: 10 },
            styles: { cellPadding: 2, fontSize: 10, overflow: 'linebreak' },
            columnStyles: {
                0: { cellWidth: 100 },
            },
        });

        doc.save(`${capitalizeFirstLetter(clientName)}_${audit.name}.pdf`);
    };




    const handleSelectOption = (option) => {
        switch (option.id) {
            case 'delete':
                handleDeleteAudit(audit.id);
                break;
            case 'excel':
                exportToExcel();
                break;
            case 'pdf':
                exportToPDF();
                break;
            default:
                break;
        }
    };

    const options = [
        { id: 'excel', label: 'Exporter en CSV', icon: SiMicrosoftexcel },
        { id: 'pdf', label: 'Exporter en PDF', icon: PiFilePdfDuotone },
    ];

    if (isAdmin) {
        options.push({
            id: 'delete',
            label: 'Supprimer',
            variant: 'danger',
            icon: CiTrash,
        });
    }



    const handleTaskUpdate = async (taskId, status) => {
        try {
            const restOperation = await patch({
                apiName: 'clientsApi',
                path: `/clients/tasks/${taskId}/status`,
                options: {
                    headers: {
                        Token: `user_token ${user?.accessToken}`,
                    },
                    body: {
                        newStatus: status
                    }
                },
            });
            const response = await restOperation.response;
            const responseData = await response.body.json();
            if (!responseData.success) {
                notifyError("Erreur lors de la mise à jour de la tâche")
            }
            return responseData;
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la tâche :", error);
        }
    };



    return (
        <Stack direction="column">
            <EditAuditPopup
                open={popups[`edit ${audit?.id}`]}
                onClose={() => closePopup(`edit ${audit?.id}`)}
                auditId={audit?.id}
            />
            <DivTrash>
                <Select
                    options={options}
                    onSelect={handleSelectOption}
                    defaultText="Sélectionner une action"
                    variant="keepDefault"
                />
            </DivTrash>
            <Stack>
                <NavLink to={ROUTES.CUSTOMER_CLIENT_DETAILS(clientId)}>
                    {logo ? (
                        <img src={logo} className="user-picture" alt="logo" />
                    ) : (
                        <img src="/illustration/svg/utilisateur.svg" className="user-picture" alt="avatar" />
                    )}
                </NavLink>
                <Stack direction={"column"} spacing={"2px"}>
                    <Title level={3}>{audit.name} pour <Span textTransform="capitalize"><Link to={ROUTES.CUSTOMER_CLIENT_DETAILS(clientId)}>{clientName}</Link></Span></Title>
                    <Text>{`Créé le ${dateConverter(audit.createdAt)}`}</Text>
                </Stack>
                {isAdmin && (
                    <>
                        <IconButton
                            wtext={"no"}
                            variant="secondary-action"
                            onClick={() => openPopup(`edit ${audit?.id}`)}
                        >
                            <CiEdit />
                        </IconButton>

                    </>
                )}
            </Stack>
            <Stack align={"center"}>
                <Pastille variant="success">{audit.type}</Pastille>
                <Pastille variant="warning">{audit.status}</Pastille>
                <Pastille variant="primary">{`Se termine le ${dateConverter(audit.targetDate)}`}</Pastille>
                <SmallCircleProgress width="80px" progress={donePercentage.toFixed(2)} showDecimals={false} />
            </Stack>
            <Stack direction={"column"}>
                {audit.documents && audit.documents.length > 0 ? (
                    <Stack flexWrap={"wrap"} spacing={"20px"}>
                        {audit.documents.map((document) => (
                            <React.Fragment key={document.id}>
                                <ViewDocumentPopup
                                    open={popups[`view ${document?.id}`]}
                                    onClose={() => closePopup(`view ${document?.id}`)}
                                    document={document}
                                />
                                <ChipButton
                                    variant={"action"}
                                    onClick={() => openPopup(`view ${document.id}`)}
                                >
                                    <PiPaperclipLight />
                                    {document.name}
                                </ChipButton>
                            </React.Fragment>
                        ))}
                    </Stack>
                ) : (
                    <Text>Aucun document associé à cet audit</Text>
                )}
                {isAdmin && (
                    <IconButton
                        width="fit-content"
                        variant="secondary-action"
                        onClick={() => openPopup("associate_document_audit")}
                    >
                        <IoMdAdd />
                        Associer un document
                    </IconButton>
                )}
            </Stack>
            {isAdmin && (
                <Stack justify="end">
                    <IconButton variant="danger" onClick={deleteCheckedTasks} enable={deleteButtonState}>
                        <CiTrash /> Supprimer ({selectedCount})
                    </IconButton>
                    <IconButton variant="action" onClick={() => openPopup(audit?.id)}>
                        <IoMdAdd /> Ajouter une Tâche
                    </IconButton>
                </Stack>
            )}
            {tasks && tasks.length > 0 ? (
                <Table>
                    <thead>
                        <tr>
                            {isAdmin && (
                                <Th variant="basique">
                                    <Checkbox
                                        type="checkbox"
                                        checked={allChecked}
                                        onChange={handleParentCheckboxChange}
                                    />
                                </Th>
                            )}
                            <Th variant="basique">Tâche</Th>
                            <Th variant="basique">Date</Th>
                            <Th variant="basique">Statut</Th>
                            <Th variant="basique">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map((task) => (
                            <tr key={task.id}>
                                <ViewTaskPopup
                                    open={popups[`view ${task?.id}`]}
                                    onClose={() => closePopup(`view ${task?.id}`)}
                                    clientId={clientId}
                                    task={task}
                                />
                                <ViewTaskPopupCustomer
                                    open={popups[`view customer ${task?.id}`]}
                                    onClose={() => closePopup(`view customer ${task?.id}`)}
                                    clientId={clientId}
                                    task={task}
                                />
                                <EditTaskPopup
                                    open={popups[`edit admin ${task?.id}`]}
                                    onClose={() => closePopup(`edit admin ${task?.id}`)}
                                    task={task}
                                />
                                <EditTaskPopupCustomer
                                    open={popups[`edit customer ${task?.id}`]}
                                    onClose={() => closePopup(`edit customer ${task?.id}`)}
                                    task={task}
                                />
                                {isAdmin && (
                                    <Td>
                                        <Checkbox
                                            type="checkbox"
                                            checked={checkedItems[task.id] || false}
                                            onChange={() => handleChildCheckboxChange(task.id)}
                                        />
                                    </Td>
                                )}
                                <Td>
                                    <Link
                                        onClick={isAdmin || isReltim ? () => openPopup(`view ${task?.id}`) : () => openPopup(`view customer ${task?.id}`)}
                                        cropped
                                    >
                                        {task.name}
                                    </Link>
                                </Td>
                                <Td>
                                    <Pastille variant={Tool.defColorDate(task).color}>
                                        {Tool.defColorDate(task).icon}{Tool.dateToFRFormat(task)}
                                    </Pastille>
                                </Td>
                                <Td>
                                    <ColoredSelect
                                        options={[
                                            { id: 'true', label: 'Terminée', variant: 'success', icon: CiCircleCheck },
                                            { id: 'false', label: 'Non terminée', variant: 'danger', icon: CiCircleRemove },
                                        ]}
                                        value={task.doneStatus === "true" ? 'true' : 'false'}
                                        onSelect={(option) => handleTaskUpdate(task.id, option.id)}
                                        defaultText="Sélectionner..."
                                    />
                                </Td>

                                <Td>
                                    <Stack>
                                        <IconButton
                                            wtext={"no"}
                                            variant="secondary-action"
                                            onClick={isAdmin ? () => openPopup(`edit admin ${task?.id}`) : () => openPopup(`edit customer ${task?.id}`)}
                                        >
                                            <CiEdit />
                                        </IconButton>
                                    </Stack>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <Stack direction="column" align="center">
                    <AnimationComponent animationData={Empty} width="150px" />
                    <Text>
                        Cet audit n'a aucune tâche
                    </Text>
                    {isAdmin ? (
                        <IconButton variant="action" onClick={() => openPopup(audit?.id)}>
                            <IoMdAdd /> Ajouter une tâche
                        </IconButton>
                    ) : (
                        <Tips variant={"warning"}>Vous n'avez pas les droits pour ajouter une tâche, contactez un admin</Tips>
                    )}
                </Stack>
            )}
            <AddTaskPopup
                open={popups[audit?.id]}
                onClose={() => closePopup(audit?.id)}
                auditId={audit?.id}
            />
            <AddEntityDocumentRelationPopup
                open={popups["associate_document_audit"]}
                onClose={() => closePopup("associate_document_audit")}
                entityId={audit?.id}
                entityClass="audit"
                entityName={audit?.name}
                clientId={clientId}
            />
        </Stack>
    );
}
