import React from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import Tips from '../../textual/Tips';

const WarningAddAudt = ({ open, onClose, clientName }) => {
    return (
        <Popup open={open} onClose={onClose} title={`Ajouter un audit à ${clientName}`}>
            <Tips variant="warning">Oups : on dirait que vous n'avez pas les permissions pour ajouter un audit à {clientName}.</Tips>
            <Text>Qui peut le faire ? Seuls les administrateurs peuvent ajouter des audits à des organisations. Vous avez beson d'un audit supplémentaires ? Contactez un administrateur de l'application.</Text>
        </Popup>
    );
};

export default WarningAddAudt;
