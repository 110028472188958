import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { getUrl } from "aws-amplify/storage";
import Loading from "../states/Loading";
import { getProfile } from '../graphql/queries';
import { generateClient, get } from 'aws-amplify/api';

const UserContext = createContext();
const client = generateClient();

export const UserProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [cognitoGroups, setCognitoGroups] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isReltim, setIsReltim] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileCompleted, setProfileCompleted] = useState(null);

  const fetchClients = useCallback(async (token) => {
    try {
      const restOperation = get({
        apiName: 'clientsApi',
        path: '/clients',
        options: {
          headers: {
            Token: `user_token ${token}`,
          },
        },
      });
      const response = await restOperation.response;
      const clientData = await response.body.json();
      return clientData.clients;
    } catch (e) {
      console.error('GET clients call failed: ', e);
    }
  }, []);

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        const session = await fetchAuthSession();
        if (session.userSub) {
          const accessToken = session.tokens.accessToken.toString();
          const identityId = session.identityId;
          const currentUser = await getCurrentUser();
          const userProfile = await getUserProfile(currentUser.userId);
          const cognitoGroups = session.tokens.idToken.payload['cognito:groups'];

          const clients = await fetchClients(accessToken);

          setCognitoGroups(cognitoGroups && cognitoGroups.length > 0 ? cognitoGroups : null);
          setIsAdmin(cognitoGroups && cognitoGroups.includes('Admin'));
          setIsReltim(cognitoGroups && cognitoGroups.includes('Reltim'));
          setIsCustomer(cognitoGroups && cognitoGroups.includes('Customer'));

          setUser({
            age: userProfile.birthdate ? calculateAge(userProfile.birthdate) : 0,
            pseudo: currentUser.username || 'User',
            id: currentUser.userId || 0,
            profile: userProfile,
            identityId,
            accessToken,
            session,
            clients,
            aud: session.tokens.idToken.payload.aud,
          });

          setProfile(userProfile);
          setLoggedIn(true);
          fetchProfilePictureURL(userProfile.profile_picture, identityId);

          checkProfileCompletion(userProfile);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'état d\'authentification :', error);
        setLoggedIn(false);
      }
    };

    checkAuthState();
  }, [fetchClients]);

  const refreshUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      const userProfile = await getUserProfile(currentUser.userId);
      const session = await fetchAuthSession();
      const identityId = session?.identityId;
      const accessToken = session.tokens.accessToken.toString();
      const cognitoGroups = session.tokens.idToken.payload['cognito:groups'];

      const clients = await fetchClients(accessToken);

      setCognitoGroups(cognitoGroups && cognitoGroups.length > 0 ? cognitoGroups : null);
      setIsAdmin(cognitoGroups && cognitoGroups.includes('Admin'));
      setIsReltim(cognitoGroups && cognitoGroups.includes('Reltim'));
      setIsCustomer(cognitoGroups && cognitoGroups.includes('Customer'));

      setUser({
        age: userProfile.birthdate ? calculateAge(userProfile.birthdate) : 0,
        pseudo: currentUser.username || 'User',
        id: currentUser.userId || 0,
        profile: userProfile,
        identityId,
        accessToken,
        session,
        clients,
      });

      setProfile(userProfile);
      setLoggedIn(true);
      fetchProfilePictureURL(userProfile.profile_picture, identityId);
      checkProfileCompletion(userProfile);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
      setLoggedIn(false);
      throw error;
    }
  };

  const login = async () => {
    try {
      await refreshUser();
    } catch (error) {
      console.error('Erreur lors de la connexion :', error);
      setLoggedIn(false);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setLoggedIn(false);
    setProfile(null);
    setProfilePictureURL(null);
    setIsAdmin(false);
    setIsReltim(false);
    setIsCustomer(false);
  };

  const fetchProfilePictureURL = async (picturePath, identityId) => {
    try {
      if (picturePath && identityId) {
        const imageObject = await getUrl({
          path: `protected/${identityId}/${picturePath}`,
          options: { expiresIn: 3600 },
        });
        setProfilePictureURL(imageObject.url);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'image de profil :', error);
    }
  };

  const checkProfileCompletion = (profile) => {
    const requiredFields = ['name', 'surname'];
    const isComplete = requiredFields.every(field => profile[field]);
    setProfileCompleted(isComplete);
  };

  const getUserProfile = async (id) => {
    try {
      const response = await client.graphql({
        query: getProfile,
        variables: { id },
      });
      return response.data.getProfile;
    } catch (error) {
      console.error('Erreur lors de la récupération du profil utilisateur :', error);
      return null;
    }
  };

  const calculateAge = (birthdate) => {
    if (!birthdate) return 0;

    const parts = birthdate.split('-');
    const birthYear = parseInt(parts[0], 10);
    const birthMonth = parseInt(parts[1], 10) - 1;
    const birthDay = parseInt(parts[2], 10);

    const birthDate = new Date(birthYear, birthMonth, birthDay);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  if (isLoggedIn === null) {
    return <Loading label='Authentification...' />;
  }

  return (
    <UserContext.Provider value={{
      isLoggedIn,
      user,
      profile,
      profilePictureURL,
      fetchProfilePictureURL,
      login,
      logout,
      refreshUser,
      cognitoGroups,
      isAdmin,
      isReltim,
      isCustomer,
      profileCompleted,
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
