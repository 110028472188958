import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './pages/private/admin/HomeScreen';
import LoginScreen from "./pages/public/LoginScreen";
import Appearance from "./pages/private/settings/Appearance";
import Authentification from "./pages/private/settings/Authentification";
import Compte from "./pages/private/settings/Compte";
import ProfilePage from "./pages/private/Profil";
import Page404 from "./pages/error/Page404";
import Error429 from "./pages/error/Error429";
import Error500 from "./pages/error/Error500";
import Clients from "./pages/private/admin/clients/Clients";
import ClientDetails from "./pages/private/admin/clients/ClientDetails";
import EditClients from "./pages/private/admin/clients/EditClients";
import AddClients from "./pages/private/admin/clients/AddClients";
import CreateAudit from "./pages/private/admin/clients/CreateAudit";
import ListAuditTemplates from "./pages/private/admin/ListAuditTemplates";
import CompleteProfile from './pages/private/CompleteProfile';
import CompleteProfileRoutes from './hooks/login-gestion/CompleteProfileRoutes';
import GetAudits from './pages/private/admin/clients/audits/GetAudits';
import DefaultLayout from './layout/DefaultLayout';
import ProtectedRoute from './hooks/login-gestion/ProtectedRoute';
import Setting from './pages/private/settings/Setting';
import ListDocuments from './pages/private/admin/documents/ListDocuments';
import Users from './pages/private/admin/Users';
import ListAllTasks from './pages/private/admin/tasks/ListAllTasks';
import { ROUTES } from './routes/routes';
import Home from './pages/private/customer/Home';
import CustomerClientDetails from './pages/private/customer/qualiopi/CustomerClientDetails';
import CustomerAuditDetails from './pages/private/customer/qualiopi/audits/CustomerAuditsDetails';

function App() {
  return (
    <Routes>
      {/* Routes publiques */}
      <Route path={ROUTES.LOGIN} element={<LoginScreen />} />

      {/* Routes accessibles sans profil */}
      <Route path={ROUTES.COMPLETE_PROFILE} element={<CompleteProfileRoutes><CompleteProfile /></CompleteProfileRoutes>} />

      {/* Routes protégées enveloppées par DefaultLayout */}
      <Route element={<ProtectedRoute />}>
        <Route element={<DefaultLayout bottombar={true} />}>
          <Route path={ROUTES.SETTINGS} element={<Setting />} />
          <Route path={ROUTES.SETTINGS_APPEARANCE} element={<Appearance />} />
          <Route path={ROUTES.SETTINGS_AUTHENTICATION} element={<Authentification />} />
          <Route path={ROUTES.SETTINGS_ACCOUNT} element={<Compte />} />
          <Route path={ROUTES.PROFILE} element={<ProfilePage />} />

          {/* Routes protégées pour le groupe Reltim */}
          <Route element={<ProtectedRoute reltimOnly={true} />}>
            <Route path={ROUTES.ADMIN_CLIENT_DETAILS(":id")} element={<ClientDetails />} />
            <Route path={ROUTES.ADMIN_GET_AUDITS(":clientId", ":auditId")} element={<GetAudits />} />
            <Route path={ROUTES.ADMIN_HOME} element={<HomeScreen />} />
            <Route path={ROUTES.ADMIN_AUDIT_TEMPLATES} element={<ListAuditTemplates />} />
            <Route path={ROUTES.ADMIN_DOCUMENTS} element={<ListDocuments />} />
            <Route path={ROUTES.ADMIN_TASKS} element={<ListAllTasks />} />
            <Route path={ROUTES.ADMIN_CLIENTS} element={<Clients />} />
          </Route>

          {/* Routes protégées pour les administrateurs uniquement */}
          <Route element={<ProtectedRoute adminOnly={true} />}>
            <Route path={ROUTES.ADMIN_USERS} element={<Users />} />
            <Route path={ROUTES.ADMIN_EDIT_CLIENT(":id")} element={<EditClients />} />
            <Route path={ROUTES.ADMIN_CREATE_AUDIT(":id")} element={<CreateAudit />} />
            <Route path={ROUTES.ADMIN_ADD_CLIENT} element={<AddClients />} />
          </Route>

          {/* Routes protégées pour le groupe Customer */}
          <Route element={<ProtectedRoute customerOnly={true} />}>
            <Route path={ROUTES.CUSTOMER_HOME} element={<Home />} />
            <Route path={ROUTES.CUSTOMER_CLIENT_DETAILS(":id")} element={<CustomerClientDetails />} />
            <Route path={ROUTES.CUSTOMER_AUDIT_DETAILS(":id", ":auditId")} element={<CustomerAuditDetails />} />
          </Route>

          <Route path={ROUTES.NOT_FOUND} element={<Page404 />} />
        </Route>
      </Route>

      {/* Routes pour les erreurs spécifiques */}
      <Route path={ROUTES.ERROR_429} element={<Error429 />} />
      <Route path={ROUTES.ERROR_500} element={<Error500 />} />

      {/* Route pour la page 404 */}
      <Route path={ROUTES.NOT_FOUND} element={<Page404 />} />
    </Routes>
  );
}

export default App;
