import React, { useState, useEffect, useCallback } from 'react';
import Hero from '../../../components/box/section/Hero.js';
import Title from '../../../components/ui/textual/Title.js';
import Table from '../../../components/ui/table/Table.js';
import Pastille from '../../../components/ui/textual/Pastille.js';
import Td from '../../../components/ui/table/Td.js';
import Th from '../../../components/ui/table/Th.js';
import Checkbox from '../../../components/ui/checkbox/CheckboxItem.js';
import FormError from '../../../components/ui/form/FormError.js';
import Loading from '../../../states/Loading.js';
import { get, del } from 'aws-amplify/api';
import { useUser } from '../../../contexts/UserContext.js';
import { usePopup } from '../../../contexts/PopupContext.js';
import ViewUserPopup from '../../../components/ui/popup/allPopups/ViewUserPopup.js';
import Link from '../../../components/ui/textual/Link.js';
import { CiCircleCheck, CiEdit, CiTrash, CiWarning } from 'react-icons/ci';
import { PiUserCircleDashedThin, PiUserCircleGearThin, PiUserListThin } from 'react-icons/pi';
import IconButton from '../../../components/ui/button/IconButton.js';
import Stack from '../../../components/box/container/Stack.js';
import AddUserPopup from '../../../components/ui/popup/allPopups/AddUserPopup.js';
import { IoMdAdd } from 'react-icons/io';
import { confirm } from '../../../components/confirm/ConfirmGlobal.js';
import { notifyError, notifySuccess } from '../../../components/ui/Toastify.js';
import InvisibleLink from '../../../components/ui/button/InvisibleLink.js';
import EditUserPopup from '../../../components/ui/popup/allPopups/EditUserPopup.js';
import fetchS3File from '../../../hooks/getdata/fetchs3File.js';

function Users() {
    const { popups, openPopup, closePopup } = usePopup();
    const { user } = useUser();
    const [users, setUsers] = useState([]);
    const [userImages, setUserImages] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const getUsers = useCallback(async () => {
        try {
            const restOperation = await get({
                apiName: 'usersApi',
                path: '/users',
                options: {
                    headers: {
                        Token: `user_token ${user.accessToken}`,
                    },
                },
            });
            const response = await restOperation.response;
            const userData = await response.body.json();
            setUsers(userData);

            const imageUrls = {};
            for (const user of userData) {
                if (user.profile?.profile_picture && user.profile.profile_picture_identity_id) {
                    imageUrls[user.username] = await fetchS3File(user.profile.profile_picture, user.profile.profile_picture_identity_id);
                }
            }
            setUserImages(imageUrls);
            setLoading(false);
        } catch (e) {
            console.error('GET users call failed: ', e);
            setError('Impossible de récupérer les utilisateurs');
            setLoading(false);
        }
    }, [user.accessToken]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    async function deleteUser(username) {
        try {
            const adminConfirmed = await confirm({
                title: "Voulez-vous vraiment supprimer cet utilisateur ?",
                content: "Cet utilisateur et son profil associé seront supprimés pour toujours.",
                variant: "danger",
            });

            if (adminConfirmed) {
                const result = await del({
                    apiName: 'usersApi',
                    path: `/users/delete/${username}`,
                    options: {
                        headers: {
                            Token: `user_token ${user.accessToken}`,
                        },
                    },
                });

                const response = await result.response;

                if (response.statusCode === 200) {
                    notifySuccess('Utilisateur supprimé avec succès');
                    getUsers();
                } else {
                    notifyError('Erreur lors de la suppréssion de l\'utilisateur');
                }
            }
        } catch (error) {
            notifyError('Erreur lors de la suppréssion de l\'utilisateur');
            console.error('Erreur lors de la suppréssion de l\'utilisateur :', error);
        }
    }

    return (
        <Hero>
            <Stack justify="space-between">
                <Title level={1}>Liste des utilisateurs</Title>
                <IconButton
                    variant="action"
                    onClick={() => openPopup("add_user")}
                    fontSize="16px"
                >
                    <IoMdAdd /> Ajouter un utilisateur
                </IconButton>
            </Stack>

            {error && <FormError variant="error">{error}</FormError>}
            {loading && <Loading label='Chargement des utilisateurs...'/>}
            {!loading && users.length === 0 && !error && (
                <Title>Aucun utilisateur disponible.</Title>
            )}

            {!loading && users.length > 0 && (
                <Table>
                    <thead>
                        <tr>
                            <Th variant="basique">
                                <Checkbox type="checkbox" />
                            </Th>
                            <Th variant="basique">Photo</Th>
                            <Th variant="basique">Email</Th>
                            <Th variant="basique">Groupe</Th>
                            <Th variant="basique">Statut</Th>
                            <Th variant="basique">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.username}>
                                <ViewUserPopup
                                    open={popups[`view ${user.username}`]}
                                    onClose={() => closePopup(`view ${user.username}`)}
                                    user={user}
                                    profile_picture={userImages[user.username] ? userImages[user.username] : null}
                                />
                                <EditUserPopup
                                    open={popups[`edit ${user.username}`]}
                                    onClose={() => closePopup(`edit ${user.username}`)}
                                    userData={user}
                                />
                                <Td>
                                    <Checkbox type="checkbox" />
                                </Td>
                                <Td>
                                    <Stack align={"center"}>
                                        <InvisibleLink onClick={() => openPopup(`view ${user.username}`)} lineheight={"0"}>
                                            <img
                                                src={userImages[user.username] || "/illustration/svg/utilisateur.svg"}
                                                alt={user.username}
                                                style={{ width: '20px', height: '20px', borderRadius: '50px', objectFit: 'cover' }}
                                            />
                                        </InvisibleLink>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Link onClick={() => openPopup(`view ${user.username}`)} cropped>
                                        {user.email}
                                    </Link>
                                </Td>
                                <Td>
                                    {user.groups && user.groups.length > 0 ? (
                                        <>
                                            {user.groups.includes('Admin') && (
                                                <Pastille variant="danger"><PiUserCircleGearThin />Administrateur</Pastille>
                                            )}
                                            {!user.groups.includes('Admin') && user.groups.includes('Reltim') && (
                                                <Pastille variant="success"><PiUserCircleDashedThin />Reltim</Pastille>
                                            )}
                                            {!user.groups.includes('Admin') && !user.groups.includes('Reltim') && user.groups.includes('Customer') && (
                                                <Pastille variant="primary"><PiUserListThin />Client</Pastille>
                                            )}
                                        </>
                                    ) : (
                                        <Pastille variant="warning"><CiWarning />Aucun groupe</Pastille>
                                    )}
                                </Td>
                                <Td>
                                    <>
                                        {user.status === 'CONFIRMED' && (
                                            <Pastille variant="success">
                                                <CiCircleCheck /> Confirmé
                                            </Pastille>
                                        )}
                                        {user.status === 'FORCE_CHANGE_PASSWORD' && (
                                            <Pastille variant="warning">
                                                <CiWarning /> Doit changer son mot de passe
                                            </Pastille>
                                        )}
                                        {user.status !== 'CONFIRMED' && user.status !== 'FORCE_CHANGE_PASSWORD' && (
                                            <Pastille variant="warning">
                                                <CiWarning /> {user.status || 'Non renseigné'}
                                            </Pastille>
                                        )}
                                    </>
                                </Td>
                                <Td>
                                    <Stack align={"center"}>
                                        <IconButton
                                            variant={"secondary-action"}
                                            wtext={"no"}
                                            onClick={() => openPopup(`edit ${user.username}`)}
                                        >
                                            <CiEdit />
                                        </IconButton>
                                        <IconButton
                                            variant={"danger"}
                                            wtext={"no"}
                                            onClick={() => deleteUser(user.username)}
                                        >
                                            <CiTrash />
                                        </IconButton>
                                    </Stack>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <AddUserPopup
                open={popups["add_user"]}
                onClose={() => closePopup("add_user")}
            />
        </Hero>
    );
}

export default Users;
