import React from 'react';
import styled from "styled-components";
import Text from "../components/ui/textual/Text";
import LoadingSpinner from './LoadingSpinner';

const Container = styled.div`
    height: ${(props) => (props.$fullPage ? '100vh' : 'auto')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default function Loading({ width, label = 'Chargement...', fullPage = true }) {
  return (
      <Container $fullPage={fullPage}>
        <LoadingSpinner width={width} />
        <Text variant="white">{label}</Text>
      </Container>
  );
}
