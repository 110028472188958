import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Tips from '../../textual/Tips';
import IconButton from '../../button/IconButton';
import { notifyError, notifySuccess } from '../../Toastify';
import { generateClient } from 'aws-amplify/api';
import { listClientProfiles, listProfiles } from '../../../../graphql/queries';
import { createClientProfiles } from '../../../../graphql/mutations';
import Checkbox from '../../checkbox/CheckboxItem';
import Stack from '../../../box/container/Stack';
import BentoCheckbox from '../../checkbox/BentoCheckbox';

const client = generateClient();

const AddProfileClientPopup = ({ open, onClose, clientName, clientId }) => {
    const [profiles, setProfiles] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);

    const handleProfileChange = (profileId) => {
        setSelectedProfiles((prev) =>
            prev.includes(profileId)
                ? prev.filter((id) => id !== profileId)
                : [...prev, profileId]
        );
    };

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const profilesResult = await client.graphql({
                    query: listProfiles,
                    authMode: 'userPool',
                });

                const profiles = profilesResult.data.listProfiles?.items || [];

                const associatedProfilesResult = await client.graphql({
                    query: listClientProfiles,
                    variables: { filter: { clientsId: { eq: clientId } } },
                    authMode: 'userPool',
                });

                const associatedProfiles = associatedProfilesResult.data.listClientProfiles?.items || [];
                const associatedProfileIds = new Set(associatedProfiles.map((item) => item.profileId));

                const nonAssociatedProfiles = profiles.filter((profile) => !associatedProfileIds.has(profile.id));

                setProfiles(nonAssociatedProfiles);
            } catch (error) {
                console.error('Erreur lors de la récupération des profils :', error);
            }
        };

        fetchProfiles();
    }, [clientId]);

    const createClientProfile = async (profileId) => {
        try {
            const existingRelationResult = await client.graphql({
                query: listClientProfiles,
                variables: {
                    filter: {
                        clientsId: { eq: clientId },
                        profileId: { eq: profileId },
                    },
                },
                authMode: 'userPool',
            });

            const existingRelations = existingRelationResult.data.listClientProfiles?.items || [];

            if (existingRelations.length > 0) {
                notifyError(`Le profil sélectionné est déjà associé à ${clientName}`);
                return false;
            }

            await client.graphql({
                query: createClientProfiles,
                variables: {
                    input: {
                        clientsId: clientId,
                        profileId,
                    },
                },
            });

            return true;
        } catch (error) {
            console.error(`Erreur lors de la création de la relation ClientProfile (client: ${clientId}, profile: ${profileId}):`, error);
            return false;
        }
    };

    const handleSubmit = async () => {
        if (selectedProfiles.length === 0) {
            notifyError("Veuillez choisir au moins un profil");
            return;
        }

        try {
            const results = await Promise.all(
                selectedProfiles.map((profileId) => createClientProfile(profileId))
            );

            if (results.includes(true)) {
                notifySuccess("Relations créées avec succès");
                onClose();
            }
        } catch (error) {
            console.error("Erreur lors de la création des relations :", error);
        }
    };

    return (
        <Popup open={open} onClose={onClose} title={`Associer des profils à ${clientName}`}>
            <Tips variant="success">
                Choisissez les profils que vous voulez associer au client {clientName}
            </Tips>
            <Stack direction={"column"} spacing={"10px"}>
                {profiles.map((profile) => (
                    <BentoCheckbox
                        key={profile.id}
                        value={profile.id}
                        type="checkbox"
                        checked={selectedProfiles.includes(profile.id)}
                        onChange={() => handleProfileChange(profile.id)}
                        label={`${profile.name} ${profile.surname}`}
                        chipVariant={"success"}
                        chip={profile.email}
                    />
                ))}
            </Stack>

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    variant="action"
                    onClick={handleSubmit}
                    enable={selectedProfiles.length === 0 ? "notactive" : undefined}
                >
                    Associer {selectedProfiles.length > 0 ? `(${selectedProfiles.length})` : ''} profils à {clientName}
                </IconButton>
            </div>
        </Popup>
    );
};

export default AddProfileClientPopup;
