import React, { useState, useEffect, useCallback } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import { generateClient } from "aws-amplify/api";
import Pastille from '../../textual/Pastille';
import * as Tool from "../../../../tools/Calculate";
import AddEntityDocumentRelationPopup from './AddEntityDocumentRelationPopup';
import IconButton from '../../button/IconButton';
import { usePopup } from '../../../../contexts/PopupContext';
import { IoMdAdd } from 'react-icons/io';
import { useData } from '../../../../contexts/DataContext';
import { listEntityDocumentRelations } from '../../../../graphqlCustom/queriesCustom';
import { useUser } from '../../../../contexts/UserContext';
import Stack from '../../../box/container/Stack';
import ChipButton from '../../button/ChipButton';
import ViewDocumentPopup from './ViewDocumentPopup';
import { PiPaperclipLight } from 'react-icons/pi';
import Title from '../../textual/Title';

const client = generateClient();


const ViewTaskPopupCustomer = ({ open, onClose, clientId, task }) => {
    const { popups, openPopup, closePopup } = usePopup();
    const [error, setError] = useState(null);

    if (!task) {
        setError("Aucune tâche")
    }


    return (
        <Popup open={open} onClose={onClose} title={`Vue de la tâche ${task?.name}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : task ? (
                <Stack direction={"column"} spacing={"30px"}>
                    <AddEntityDocumentRelationPopup
                        open={popups["associate_document_task"]}
                        onClose={() => closePopup("associate_document_task")}
                        entityId={task?.id}
                        entityClass="task"
                        entityName={task?.name}
                        clientId={clientId}
                    />
                    <Stack direction={"column"}>
                        <Title level={4}>Date échéante</Title>
                        <Pastille variant={Tool.defColorDate(task).color}>
                            {Tool.defColorDate(task).icon}{Tool.dateToFRFormat(task)}
                        </Pastille>
                    </Stack>
                    <Stack direction={"column"}>
                        <Title level={4}>Description</Title>
                        <Text>{task?.description}</Text>
                    </Stack>
                    <Stack direction={"column"}>
                        <Title level={4}>Documents associés</Title>
                        {task.documents && task.documents.length > 0 ? (
                            <Stack flexWrap={"wrap"} spacing={"20px"}>
                                {task.documents.map((document) => (
                                    <React.Fragment key={document.id}>
                                        <ViewDocumentPopup
                                            open={popups[`view ${document?.id}`]}
                                            onClose={() => closePopup(`view ${document?.id}`)}
                                            document={document}
                                        />
                                        <ChipButton
                                            variant={"action"}
                                            onClick={() => openPopup(`view ${document.id}`)}
                                        >
                                            <PiPaperclipLight />
                                            {document.name}
                                        </ChipButton>
                                    </React.Fragment>
                                ))}
                            </Stack>
                        ) : (
                            <Text>Aucun document associé à cette tâche</Text>
                        )}
                    </Stack>
                </Stack>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default ViewTaskPopupCustomer;
