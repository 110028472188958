import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import BackgroundImageContainer from "../../../../components/box/container/BackgroundImageContainer";
import Title from "../../../../components/ui/textual/Title";
import Text from "../../../../components/ui/textual/Text";
import Section from "../../../../components/box/section/Section";
import { useUser } from '../../../../contexts/UserContext';
import { get } from 'aws-amplify/api';
import NotFound from '../../../../states/NotFound';
import Loading from '../../../../states/Loading';
import fetchS3File from '../../../../hooks/getdata/fetchs3File';
import Pastille from '../../../../components/ui/textual/Pastille';
import { CiEdit, CiExport, CiMail } from 'react-icons/ci';
import { IoMdAdd } from 'react-icons/io';
import { ROUTES } from '../../../../routes/routes';
import Tips from '../../../../components/ui/textual/Tips';
import Column from '../../../../components/box/container/Column';
import Container from "../../../../components/box/container/Container";
import Stack from '../../../../components/box/container/Stack';
import Bento from '../../../../components/box/bento/Bento';
import IconButton from '../../../../components/ui/button/IconButton';
import Span from '../../../../components/ui/textual/Span';
import { usePopup } from '../../../../contexts/PopupContext';
import WarningAddAudt from '../../../../components/ui/popup/allPopups/WarningAddAudit';
import Link from '../../../../components/ui/textual/Link';
import SmallCircleProgress from '../../../../components/ui/progress-bar/SmallCircleProgress';
import Td from '../../../../components/ui/table/Td';
import EditAuditPopup from '../../../../components/ui/popup/allPopups/EditAuditPopup';
import Th from '../../../../components/ui/table/Th';
import { Table } from '@aws-amplify/ui-react';
import AnimationComponent from '../../../../components/animation/Animation';
import { dateConverter } from '../../../../tools/dateConverter';
import Empty from '../../../../components/animation/storageAnimation/empty.json';
import Checkbox from "../../../../components/ui/checkbox/CheckboxItem";
import ExportsAuditsPopup from '../../../../components/ui/popup/allPopups/ExportsAuditsPopup';
import ChipButton from '../../../../components/ui/button/ChipButton';
import ViewProfilePopup from '../../../../components/ui/popup/allPopups/ViewProfilePopup';
import ViewDocumentPopup from '../../../../components/ui/popup/allPopups/ViewDocumentPopup';
import { PiPaperclipLight } from 'react-icons/pi';

export default function CustomerClientDetails() {
  const { user, profilePictureURL, isAdmin } = useUser();
  const { popups, openPopup, closePopup } = usePopup();
  const [clientDetail, setClientDetail] = useState(null);
  const [logo, setLogo] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [auditChecked, setAuditChecked] = useState("notactive");
  const [userImages, setUserImages] = useState({});

  const calculateGlobalProgress = () => {
    const totalAudits = clientDetail.audits.length;

    if (totalAudits === 0) return 0;

    let totalTasks = 0;
    let totalDoneTasks = 0;

    clientDetail.audits.forEach(audit => {
      const auditTasks = audit.tasks || [];
      totalTasks += auditTasks.length;
      totalDoneTasks += auditTasks.filter(task => task.doneStatus === "true").length;
    });

    if (totalTasks === 0) return 0;

    const globalProgress = (totalDoneTasks / totalTasks) * 100;
    return globalProgress;
  };

  const fetchClient = useCallback(async (token) => {
    try {
      const restOperation = await get({
        apiName: 'clientsApi',
        path: `/clients/${id}`,
        options: {
          headers: {
            Token: `user_token ${token}`,
          },
        },
      });

      const response = await restOperation.response;
      const clientData = await response.body.json();
      return clientData.client;
    } catch {
      console.error("error during fetch");
      return null;
    }
  }, [id]);

  useEffect(() => {
    const loadClientDetails = async () => {
      if (user?.accessToken) {
        setSelectedCount(0);
        setAllChecked(false);
        setCheckedItems({});
        setAuditChecked('notactive');
        const clientData = await fetchClient(user.accessToken);
        if (!clientData) {
          setError(404);
        } else {
          setClientDetail(clientData);
          if (clientData && clientData.logo) {
            const logoUrl = await fetchS3File(clientData.logo, clientData.logoIdentityId);
            setLogo(logoUrl);
          } else {
            setLogo(null);
          }
        }
      }
    };

    loadClientDetails();
  }, [fetchClient, user?.accessToken, id]);


  useEffect(() => {
    const loadProfilePictures = async () => {
      if (!clientDetail?.profiles) return;

      const images = await Promise.all(
        clientDetail.profiles.map(async (profile) => {
          if (profile.profile_picture && profile.profile_picture_identity_id) {
            const imageUrl = await fetchS3File(profile.profile_picture, profile.profile_picture_identity_id);
            return { id: profile.id, imageUrl };
          }
          return { id: profile.id, imageUrl: null };
        })
      );

      const imagesMap = images.reduce((acc, { id, imageUrl }) => {
        acc[id] = imageUrl;
        return acc;
      }, {});

      setUserImages(imagesMap);
    };

    loadProfilePictures();
  }, [clientDetail]);


  const handleChildCheckboxChange = (auditId) => {
    const updatedCheckedItems = { ...checkedItems, [auditId]: !checkedItems[auditId] };
    const count = Object.values(updatedCheckedItems).filter((value) => value).length;
    setSelectedCount(count);
    setCheckedItems(updatedCheckedItems);
    setAllChecked(count === clientDetail.audits.length);
  };

  const handleParentCheckboxChange = () => {
    const updatedCheckedItems = {};
    if (!allChecked) {
      clientDetail.audits.forEach((audit) => {
        updatedCheckedItems[audit.id] = true;
      });
    }
    setSelectedCount(Object.keys(updatedCheckedItems).length);
    setCheckedItems(updatedCheckedItems);
    setAllChecked(!allChecked);
  };

  useEffect(() => {
    if (selectedCount > 0) {
      setAuditChecked("active");
    } else {
      setAuditChecked("notactive");
    }
  }, [selectedCount]);

  const getSelectedAudits = () => {
    if (!clientDetail || !clientDetail.audits) {
      return [];
    }
    return clientDetail.audits.filter(audit => checkedItems[audit.id]);
  };



  if (error === 404) {
    return <NotFound />;
  }

  if (error) {
    return <p>An unknown error occurred. Please try again later.</p>;
  }

  if (!clientDetail) return <Loading label='Chargement de votre espace...' />;

  return (
    <>
      <Section>
        <BackgroundImageContainer coverUrl="/background/cover_4.jpg">
          <div className="profil-container">
            <img
              src={logo || "/illustration/svg/utilisateur.svg"}
              className="user-picture"
              alt={clientDetail.name || "avatar"}
            />
            <div className="user-info">
              <div>
                <Title textTransform="capitalize" level={3} variant="white">
                  {clientDetail.name}
                </Title>
                <Text variant="white">{clientDetail.description}</Text>
              </div>
            </div>
          </div>
        </BackgroundImageContainer>
      </Section>
      <Section>
        <Container direction="row" align="start" width="100%">
          <Column width="50%" gap="20px">
            <Bento highlight="highlight" spacing="30px">
              <Stack direction={"column"}>
                <Title level={4}>
                  Informations
                </Title>
                {clientDetail.email ? (
                  <Pastille variant="success">
                    <CiMail />{clientDetail.email}
                  </Pastille>
                ) : (
                  <Text><Span textTransform={"capitalize"}>{clientDetail.name}</Span> n'a pas d'email'</Text>
                )}
              </Stack>
              <Stack direction={"column"}>
                <Title level={4}>
                  Documents
                </Title>
                {clientDetail.documents && clientDetail.documents.length > 0 ? (
                  <Stack flexWrap={"wrap"} spacing={"10px"}>
                    {clientDetail.documents.map((document) => (
                      <React.Fragment key={document.id}>
                        <ViewDocumentPopup
                          open={popups[`view ${document?.id}`]}
                          onClose={() => closePopup(`view ${document?.id}`)}
                          document={document}
                        />
                        <ChipButton
                          variant={"action"}
                          onClick={() => openPopup(`view ${document.id}`)}
                        >
                          <PiPaperclipLight />
                          {document.name}
                        </ChipButton>
                      </React.Fragment>
                    ))}
                  </Stack>
                ) : (
                  <Text>Aucun document associé à ce client</Text>
                )}
              </Stack>
              {clientDetail.profiles && clientDetail.profiles.length > 0 ? (
                <Stack direction={"column"}>
                  <Title level={4}>
                    Membres de <Span textTransform={"capitalize"}>{clientDetail.name}</Span> ({clientDetail.profiles.length})
                  </Title>
                  <Stack flexWrap={"wrap"} spacing={"10px"}>
                    {clientDetail.profiles.map((profile) => (
                      <React.Fragment key={profile.id}>
                        <ViewProfilePopup
                          open={popups[`view ${profile?.id}`]}
                          onClose={() => closePopup(`view ${profile?.id}`)}
                          profile={profile}
                          profile_picture={userImages[profile.id]}
                        />
                        <ChipButton
                          variant={"secondary-action"}
                          onClick={() => openPopup(`view ${profile.id}`)}
                        >
                          <img
                            src={userImages[profile.id] || "/illustration/svg/utilisateur.svg"}
                            alt={`${profile.name} ${profile.surname}`}
                            style={{ width: '20px', height: '20px', borderRadius: '50px', objectFit: 'cover' }}
                          />
                          {profile.name} {profile.surname}
                        </ChipButton>

                      </React.Fragment>
                    ))}
                  </Stack>
                </Stack>
              ) : (
                <Text>Aucun utilisateur n'est associé à <Span textTransform={"capitalize"}>{clientDetail.name}</Span></Text>
              )}
            </Bento>
          </Column>
          <Column width="50%" gap="50px">
            <Bento highlight={"highlight"} overflow={"visible"}>
              <Stack direction={"column"}>
                <Stack separator align={"center"} spacing={"20px"}>
                  <img
                    src={profilePictureURL || "/illustration/svg/utilisateur.svg"}
                    className="user-picture"
                    alt="avatar"
                  />
                  <Stack direction={"column"}>
                    <Title level={5}>
                      Que voulez-vous faire ?
                    </Title>
                    <Text>
                      Voici les actions pour le client <Span textTransform={"capitalize"}>{clientDetail.name}</Span>
                    </Text>
                  </Stack>
                </Stack>
                {isAdmin ? (
                  <Stack>
                    <IconButton variant="action" to={ROUTES.ADMIN_CREATE_AUDIT(clientDetail.id)}>
                      <IoMdAdd /> Ajouter un audit
                    </IconButton>
                    <IconButton variant="secondary-action" to={ROUTES.ADMIN_EDIT_CLIENT(clientDetail.id)}>
                      <CiEdit /> Modifier les informations
                    </IconButton>
                  </Stack>
                ) :
                  <Stack>
                    <IconButton variant="action" onClick={() => openPopup('add_audit_warning')}>
                      <IoMdAdd /> Ajouter un audit
                    </IconButton>
                  </Stack>
                }
              </Stack>
            </Bento>
          </Column>
        </Container>
      </Section >
      <Section margin={"50px 0px"}>
        <Stack direction={"column"} spacing={"10px"}>
          {clientDetail?.audits?.length > 0 ? (
            <>
              <Stack justify={"space-between"}>
                <Stack spacing="20px">
                  <Title level={1}>Audits de <Span textTransform={"capitalize"}>{clientDetail.name}</Span></Title>
                  <SmallCircleProgress progress={calculateGlobalProgress()} showDecimals={false} />
                </Stack>
                <Stack spacing={"10px"}>
                  <IconButton
                    fontSize={"16px"}
                    enable={auditChecked}
                    width="fit-content"
                    variant="secondary-action"
                    onClick={() => openPopup("exports")}
                  >
                    <CiExport /> Exporter ({selectedCount})
                  </IconButton>
                </Stack>
              </Stack>
              <Table>
                <thead>
                  <tr>
                    <Th variant="basique">
                      <Checkbox
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleParentCheckboxChange} />
                    </Th>
                    <Th variant="basique">Nom de l'audit</Th>
                    <Th variant="basique">Tâches</Th>
                    <Th variant="basique">Date limite</Th>
                    <Th variant="basique">Progression</Th>
                    {isAdmin && (
                      <Th variant="basique">Action</Th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {clientDetail.audits.map((audit) => {
                    const totalTasks = audit.tasks ? audit.tasks.length : 0;
                    const doneTasks = audit.tasks ? audit.tasks.filter(task => task.doneStatus === "true").length : 0;
                    const donePercentage = totalTasks ? (doneTasks / totalTasks) * 100 : 0;

                    return (
                      <tr key={audit.id}>
                        <EditAuditPopup
                          open={popups[`edit ${audit?.id}`]}
                          onClose={() => closePopup(`edit ${audit?.id}`)}
                          auditId={audit?.id}
                        />
                        <Td>
                          <Checkbox
                            checked={checkedItems[audit.id] || false}
                            onChange={() => handleChildCheckboxChange(audit.id)}
                          />
                        </Td>
                        <Td>
                          <Stack align={"center"}>
                            <Link textTransform={"capitalize"} to={ROUTES.CUSTOMER_AUDIT_DETAILS(clientDetail.id, audit.id)} cropped>
                              {audit.name}
                            </Link>
                          </Stack>
                        </Td>
                        <Td>
                          <Pastille variant={totalTasks > 1 ? "success" : totalTasks === 1 ? "warning" : "danger"}>
                            {totalTasks > 0 ? `${totalTasks} tâche${totalTasks > 1 ? "s" : ""}` : "Aucune tâche"}
                          </Pastille>
                        </Td>
                        <Td>
                          <Pastille variant={"primary"}>{`${dateConverter(audit.targetDate)}`}</Pastille>
                        </Td>
                        <Td>
                          <SmallCircleProgress progress={donePercentage.toFixed(2)} showDecimals={false} />
                        </Td>
                        {isAdmin && (
                          <Td>
                            <Stack>
                              <IconButton
                                wtext={"no"}
                                variant="secondary-action"
                                onClick={() => openPopup(`edit ${audit?.id}`)}
                              >
                                <CiEdit />
                              </IconButton>
                            </Stack>
                          </Td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <Stack direction="column" align="center">
              <AnimationComponent animationData={Empty} width="150px" />
              <Text>
                <Span textTransform={"capitalize"}>{clientDetail.name}</Span> n'a aucun audit
              </Text>
              {isAdmin ? (
                <IconButton variant="action" to={ROUTES.ADMIN_CREATE_AUDIT(clientDetail.id)}>
                  <IoMdAdd /> Ajouter un audit
                </IconButton>
              ) : (
                <Tips variant={"warning"}>
                  Vous n'avez pas les droits pour ajouter un audit, contactez un admin
                </Tips>
              )}
            </Stack>
          )}
        </Stack>
      </Section>
      <WarningAddAudt
        open={popups["add_audit_warning"]}
        onClose={() => closePopup("add_audit_warning")}
        clientName={clientDetail.name}
      />
      <ExportsAuditsPopup
        open={popups["exports"]}
        onClose={() => closePopup("exports")}
        audits={getSelectedAudits()}
        clientName={clientDetail.name}
        logo={logo}
      />
    </>
  );
}
