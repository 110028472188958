import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import Textarea from '../../form/Textarea';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { notifySuccess, notifyError } from '../../Toastify';
import { patch } from 'aws-amplify/api';
import { useUser } from '../../../../contexts/UserContext';


const EditTaskPopupCustomer = ({ open, onClose, task }) => {
    const [error, setError] = useState(null);
    const { user } = useUser();
    const [taskDescription, setTaskDescription] = useState(task?.description);
    const [isLoading, setIsLoading] = useState(false);

    const handleTaskUpdateDescription = async () => {
        setIsLoading(true);
        try {
            const restOperation = await patch({
                apiName: 'clientsApi',
                path: `/clients/tasks/${task.id}/description`,
                options: {
                    headers: {
                        Token: `user_token ${user?.accessToken}`,
                    },
                    body: {
                        newDescription: taskDescription || ""
                    }
                },
            });
            const response = await restOperation.response;
            const responseData = await response.body.json();
            if (!responseData.success) {
                notifyError("Erreur lors de la mise à jour de la tâche")
            } else {
                notifySuccess("La tâche a été mise à jour.")
            }
            onClose();
            setIsLoading(false);
            return responseData;
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la tâche :", error);
            notifyError("Erreur lors de la mise à jour de la tâche")
            setIsLoading(false);
        }
    };

    if (!task) {
        setError("Aucune tâche")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Compléter la tâche ${task?.name}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : task ? (
                <>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <Textarea
                                variant={"blue"}
                                label={"Description"}
                                value={taskDescription}
                                onChange={(e) => setTaskDescription(e.target.value)}
                                maxCharCount={250}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleTaskUpdateDescription} loading={isLoading}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default EditTaskPopupCustomer;
