export const ROUTES = {
  // Routes publiques
  LOGIN: '/se-connecter',

  // Routes protégées pour
  CUSTOMER_HOME: '/',
  CUSTOMER_CLIENT_DETAILS: (id) => `/qualiopi/${id}`,
  CUSTOMER_AUDIT_DETAILS: (id, auditId) => `/qualiopi/${id}/${auditId}`,

  // Routes accessibles sans profil
  COMPLETE_PROFILE: '/completer-mon-profil',

  // Routes protégées avec préfixe /admin
  ADMIN_HOME: '/admin',
  ADMIN_CLIENTS: '/admin/clients',
  ADMIN_CLIENT_DETAILS: (id) => `/admin/clients/${id}`,
  ADMIN_EDIT_CLIENT: (id) => `/admin/clients/${id}/modifier`,
  ADMIN_ADD_CLIENT: '/admin/clients/ajouter-un-client',
  ADMIN_CREATE_AUDIT: (id) => `/admin/clients/${id}/ajouter-un-audit`,
  ADMIN_GET_AUDITS: (clientId, auditId) => `/admin/${clientId}/${auditId}`,
  ADMIN_AUDIT_TEMPLATES: '/admin/audit-templates',
  ADMIN_DOCUMENTS: '/admin/documents',
  ADMIN_TASKS: '/admin/taches',
  ADMIN_USERS: '/admin/utilisateurs',

  // Routes pour les paramètres
  SETTINGS: '/parametres',
  SETTINGS_APPEARANCE: '/parametres/apparence',
  SETTINGS_AUTHENTICATION: '/parametres/authentification',
  SETTINGS_ACCOUNT: '/parametres/compte',

  // Routes utilisateur
  PROFILE: '/profil',

  // Routes erreurs spécifiques
  ERROR_429: '/erreur-429',
  ERROR_500: '/erreur-500',

  // Routes par défaut
  NOT_FOUND: '*',
};
